import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/Blocks/SEO'
import Home from '../components/Pages/Home'

function HomePage({
  data: {
    allCasinoJson: { nodes },
  },
  ...props
}) {
  return (
    <React.Fragment>
      <SEO
        title="Svenska casinosidor på nätet (2020)."
        description="CasinoIndex.se guidar dig till de bästa casinosidorna på nätet 2020"
      />
      <Home toplist={nodes} />
    </React.Fragment>
  )
}

export default HomePage

export const query = graphql`
  query CasinoToplist {
    allCasinoJson(sort: { fields: index, order: DESC }, limit: 5) {
      nodes {
        name
        license
        account
        url
        logo {
          src
          bg
        }
      }
    }
  }
`
