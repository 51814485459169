import React from 'react'
import { Link } from 'gatsby'
import styles from './Home.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Hero from '../Blocks/Hero'
import Content from '../Blocks/Content'
import List from '../Elements/List'
import ListItem from '../Elements/ListItem'
import TextBorder from '../Blocks/TextBorder'

function Home({ toplist, ...props }) {
  return (
    <React.Fragment>
      <Header>
        <Hero
          main
          heading="Din guide till de bästa svenska casinosidorna på nätet 2020"
        >
          <p className={styles.intro}>
            CasinoIndex hjälper dig att hitta{' '}
            <strong>svenska casinosidor</strong> med roliga spelautomater och
            bra bonus. Alla med licens enligt den svenska spellagen.
          </p>
          <div className={styles.heroImage}>
            <img src="/casino-marker.svg" alt="Spelmarker ikon" />
          </div>
        </Hero>
      </Header>
      <Page>
        <TextBorder>
          <span className={styles.bold}>Nyhet!</span> Testa{' '}
          <Link className={styles.link} to="/bonuskompassen">
            Bonuskompassen
          </Link>{' '}
          för att hitta nya bonusar.
        </TextBorder>
        <section className={styles.popular}>
          <Content>
            <h2 className={styles.heading}>Populära svenska casinosidor</h2>
            <p>
              Nedan hittar du några av de mest populära svenska casinosidorna
              idag.
            </p>
            <List>
              {toplist &&
                toplist.length > 0 &&
                toplist.map((casino, index) => (
                  <ListItem item={casino} key={index} />
                ))}
            </List>
          </Content>
        </section>
        <section className={styles.bonuscompass}>
          <Content>
            <h2 className={styles.heading__light}>
              Hitta nya bonusar med Bonuskompassen
            </h2>
            <p>
              Då bonus numer är reglerat via spellagen (2018:2238) och
              spelförordningen (2018:1475) får inte spelbolagen dela ut nya
              bonusar hur som helst. En bonus är kopplad till ett spelbolags
              spellicens och får endast användas vid första tillfället en
              spelare spelar på någon av spelbolagets casinosidor. Eftersom att
              ett spelbolag kan ha flera casinosidor under samma spellicens har
              det blivit färre möjligheter för spelare att ta del av nya
              bonusar.
            </p>
            <p>
              Med anledning av det har vi skapat verktyget Bonuskompassen.
              Bonuskompassen ger en snabb överblick över de svenska casinosidor
              där du som spelare fortfarande har möjlighet att få bonus. I
              Bonuskompassen anger du på vilka casinosidor du har utnyttjat en
              bonus och verktyget filtrar därefter bort alla andra casinosidor
              som går under samma licens som de angivna sidorna. Detta gör att
              att listan endast visar de sidor som ger möjlighet till nya
              bonusar.
            </p>
            <Link className={styles.linkButton} to="/bonuskompassen">
              Hitta nya bonusar
            </Link>
          </Content>
        </section>
        <section className={styles.faq}>
          <Content>
            <div className={styles.sectionHeader}>
              <h2 className={styles.heading}>
                Vanliga frågor om casinosidor på nätet
              </h2>
              <div className={styles.sectionImg}>
                <img
                  src="/fragor_svar.svg"
                  alt="Ikon för frågor och svar om casinosidor på nätet"
                />
              </div>
            </div>
            <dl className={styles.qna}>
              <dt className={styles.question}>
                Är det säkert att spela på casinosidor på nätet?
              </dt>
              <dd className={styles.answer}>
                CasinoIndex.se rekommenderar säkra casinosidor som har licens
                enligt den svenska spellagen (2018:1138). Det är dock inte
                alltid helt enkelt att avgöra om en casinosida är säker. Kolla
                alltid så att sidan du besöker har en säker anslutning, det vill
                säga att webbadressen till sidan använder https och inte bara
                http. Det är också en god idé att kolla så att spelsidan
                erbjuder välkända betalningsalternativ.
              </dd>
              <dt className={styles.question}>
                Är det lagligt att spela på casino online?
              </dt>
              <dd className={styles.answer}>
                Ja, om man är 18 år eller äldre är det lagligt att spela på
                nätcasino.
              </dd>
              <dt className={styles.question}>
                Varför ska jag välja ett svenskt casino?
              </dt>
              <dd className={styles.answer}>
                I och med den nya spellagen måste alla casinon som bedriver
                verksamhet eller marknadsför sig mot Sverige inneha en
                spellicens. Det gör att spelaren kan känna sig trygg och att
                alla vinster är skattefria.
              </dd>
              <dt className={styles.question}>
                Hur gammal måste man vara för att spela på nätet?
              </dt>
              <dd className={styles.answer}>
                Åldersgränsen för att spela casino på nätet är 18 år. För att
                uppfylla kraven som ställs på de spelbolag som har licens i
                Sverige måste casinosidan tydligt ange åldersgränsen samt ha
                rutiner för att säkerställa att underåriga inte har möjlighet
                att spela.
              </dd>
              <dt className={styles.question}>
                Är vinster på casino skattefria?
              </dt>
              <dd className={styles.answer}>
                Om casinosidan du spelar på har licens enligt spellagen
                (2018:1138) är alla vinster skattefria. På CasinoIndex.se har
                alla casinosidor vi länkar till licens enligt spellagen.
              </dd>
            </dl>
          </Content>
        </section>
        <section className={styles.addiction}>
          <Content>
            <h2 className={styles.heading}>Spelproblem</h2>
            <p>
              Det är långt ifrån alla som har problem med sitt spelande men
              spelproblem syns inte och det kan därför vara svårt att veta om
              man själv eller någon i ens närhet har spelproblem. Enligt
              Folkhälsomyndigheten är det ca 2% av befolkningen som har problem
              med sitt spelande. Om du upplever att livet, för dig eller någon i
              din närhet, får negativa konsekvenser på grund av spelandet kan
              det vara ett tecken på spelproblem. Du kan läsa mer om spelproblem
              och vart du kan vända dig för att få hjälp på vår sida med{' '}
              <Link className={styles.link} to="/spelproblem">
                information om spelproblem
              </Link>
              .
            </p>
          </Content>
        </section>
      </Page>
    </React.Fragment>
  )
}

export default Home
