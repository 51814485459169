import React from 'react'
import styles from './TextBorder.module.css'
import Content from './Content'

function TextBorder({ children, ...props }) {
  return (
    <div className={styles.border}>
      <Content>{children}</Content>
    </div>
  )
}

export default TextBorder
